<template>
  <div class="page">
    <Navbar title="个人认证" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="person.name"
          name="姓名"
          label="姓名"
          placeholder="请输入求职者姓名"
          :required="true"
          :rules="[{ required: true, message: '请输入求职者姓名' }]"
        />
        <van-field
          v-model="person.identity"
          name="身份证号"
          label="身份证号"
          @change="identityChange"
          placeholder="请输入求职者身份证号"
        />
        <van-field name="uploader" label="身份证照片">
          <template #input>
            <van-row class="identityImg">
              <van-col span="12"
                ><van-uploader
                  v-model="idtFontImages"
                  :after-read="idtFontRead"
                  :before-delete="imageDelete"
                  :max-count="1"
                /><br /><span class="tips fc-orange">正面照片</span></van-col
              >
              <van-col span="12"
                ><van-uploader
                  v-model="idtBackImages"
                  :after-read="idtBackRead"
                  :before-delete="imageDelete"
                  :max-count="1"
                /><br /><span class="tips fc-orange">背面照片</span></van-col
              >
            </van-row>
          </template>
        </van-field>
        <van-field
          v-model="person.birthday"
          name="出生日期"
          label="出生日期"
          placeholder="请输入求职者生日"
          readonly
          clickable
          @click="birthdayShow = true"
          :required="true"
          :rules="[{ required: true, message: '请选择出生日期' }]"
        >
        </van-field>
        <van-popup :show="birthdayShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            @cancel="birthdayShow = false"
            @confirm="confirmBirthday"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
        <van-field name="uploader" label="个人照片">
          <template #input>
            <van-uploader
              v-model="photoImages"
              :after-read="photoRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上传个人2寸证件照片</span>
          </template>
        </van-field>
        <van-field
          v-model="person.wechat"
          label="微信号码"
          type="tel"
          placeholder="请输入微信号码"
          :rules="[{ required: true, message: '请填写微信号码' }]"
        >
        </van-field>
        <van-field
          v-model="person.phone"
          label="手机号码"
          type="tel"
          :required="true"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="person.vcode"
          center
          clearable
          label="短信验证码"
          :required="true"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        ></van-field>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
      <van-row> </van-row>
    </van-form>
    <Share ref="share" />
    <loading :show="loadingShow" />
  </div>
</template>
<script>
import { reactive } from 'vue'
import { GolDateFormat } from '../../../../plugins/common/date.js'
import { GolIdentityCheck } from '../../../../plugins/common/verify.js'
import { Form, Field, CellGroup, RadioGroup, Radio, Picker, Popup, Area, Steps, Step, Uploader, DatetimePicker } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Uploader.name]: Uploader,
    [DatetimePicker.name]: DatetimePicker
  },
  setup () {
    const state = reactive()
    return {
      state
    }
  },
  data () {
    return {
      operatorCode: '',
      code: '',
      inviterCode: '',
      module: '',
      photoImages: [],
      idtFontImages: [],
      idtBackImages: [],
      minDate: new Date(1950, 1, 1),
      maxDate: new Date(),
      currentDate: new Date(1988, 1, 1),
      person: {
        name: '',
        identity: '',
        birthday: '',
        idtfCode: '',
        idtbCode: '',
        photoCode: '',
        wechat: '',
        phone: '',
        vcode: ''
      },
      step: 0,
      sendBtnTime: 0,
      genders: [],
      loadingShow: false,
      birthdayShow: false,
      validateState: true
    }
  },
  mounted () {
    var query = this.$route.query
    this.code = query.code
    this.module = query.module
    var inviterCode = query.inviterCode
    if (inviterCode !== '' && inviterCode !== undefined) {
      this.inviterCode = inviterCode
    }
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.retrievePerson()
    this.$refs.share.default('main')
  },
  methods: {
    async imageDelete (image) {
      var pd = { code: image.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (image.key === 'PEP_PSN_CPG') {
          this.person.photoCode = ''
        } else if (image.key === 'PEP_PSN_IDTF') {
          this.person.idtfCode = ''
        } else if (image.key === 'PEP_PSN_IDTB') {
          this.person.idtbCode = ''
        }
        return true
      } else {
        return false
      }
    },
    photoRead (file) {
      file.key = 'PEP_PSN_CPG'
      this.uploadImage(file, '证件照片')
    },
    identityChange () {
      if (GolIdentityCheck(this.person.identity)) {
        var years = this.person.identity.substr(6, 4)
        var month = this.person.identity.substr(10, 2)
        var day = this.person.identity.substr(12, 2)
        this.person.birthday = years + '-' + month + '-' + day
        this.currentDate = new Date(parseInt(years), parseInt(month) - 1, parseInt(day))
      } else {
        this.loadingShow = false
        this.person.identity = ''
        this.$dialog.alert({
          title: '信息提示',
          message: '身份证号不符合规则'
        })
      }
    },
    idtFontRead (file) {
      file.key = 'PEP_PSN_IDTF'
      this.uploadImage(file, '身份证照片-正面')
    },
    idtBackRead (file) {
      file.key = 'PEP_PSN_IDTB'
      this.uploadImage(file, '身份证照片-反面')
    },
    confirmBirthday (val) {
      this.person.birthday = GolDateFormat(val)
      this.birthdayShow = false
    },
    async uploadImage (file, alias) {
      var that = this
      this.loadingShow = true
      var pd = { dictKey: file.key, content: file.content, name: file.file.name, alias: alias, attach: 'photo' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/uploadImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        file.code = res.data.code
        if (file.key === 'PEP_PSN_CPG') {
          that.person.photoCode = res.data.code
        } else if (file.key === 'PEP_PSN_IDTF') {
          that.person.idtfCode = res.data.code
        } else if (file.key === 'PEP_PSN_IDTB') {
          that.person.idtbCode = res.data.code
        }
      }
      this.loadingShow = false
    },
    async submit () {
      this.loadingShow = true
      var pd = {
        code: this.code,
        name: this.person.name,
        identity: this.person.identity,
        birthday: this.person.birthday,
        idtfCode: this.person.idtfCode,
        idtbCode: this.person.idtbCode,
        wechat: this.person.wechat,
        phone: this.person.phone,
        photoCode: this.person.photoCode,
        validate: this.person.vcode
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/updatePerson', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '信息提示',
          message: '修改个人信息成功'
        }).then(() => {
          this.returnPre()
        })
      } else {
        this.$dialog.alert({
          title: '信息提示',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrievePerson () {
      var pd = { code: this.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.person.name = res.data.name
        this.person.wechat = res.data.wechat
        this.person.phone = res.data.phone
        this.person.identity = res.data.identity
        this.person.birthday = res.data.birthday
        var births = this.person.birthday.split('-')
        this.currentDate = new Date(parseInt(births[0]), parseInt(births[1]) - 1, parseInt(births[2]))
        this.person.photoCode = res.data.photoCode
        if (res.data.photoCode !== undefined && res.data.photoCode !== '') {
          this.photoImages.push({ url: res.data.photoPath, code: res.data.photoCode, key: 'PEP_PSN_CPG' })
        }
        this.person.idtfCode = res.data.idtfCode
        if (res.data.idtfCode !== undefined && res.data.idtfCode !== '') {
          this.idtFontImages.push({ url: res.data.idtfPath, code: res.data.idtfCode, key: 'PEP_PSN_IDTF' })
        }
        this.person.idtbCode = res.data.idtbCode
        if (res.data.idtbCode !== undefined && res.data.idtbCode !== '') {
          this.idtBackImages.push({ url: res.data.idtbPath, code: res.data.idtbCode, key: 'PEP_PSN_IDTB' })
        }
      }
    },
    async sendValidate () {
      var pd = { phone: this.person.phone }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    },
    returnPre () {
      if (this.module === 'RSM') {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.identityImg {
  text-align: center;
}
.tips {
  font-size: 12px;
  line-height: 18px;
}
.step {
  text-align: left;
}
.form {
  padding: 20px 5px;
}
.subbtn {
  margin: 20px auto;
}
.btn {
  width: 120px;
}
</style>
