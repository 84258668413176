module.exports = {
    GolIdentityCheck (number) {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (reg.test(number)) {
            return true
        } else {
            return false
        }
    }
}
